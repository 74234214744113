import React, { useRef } from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Image from 'gatsby-image';

import Layout from '../components/layout';
import usePercentageScrolled from '../components/usePercentageScrolled';
import SEO from '../components/seo';
import StyledButton from '../components/styles/StyledButton';
import Spacer from '../components/styles/Spacer';
import mediaqueries from '../components/mediaqueries';
import P from '../components/styles/P';

const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 940px;
  margin: 0;
  text-align: center;
  font-weight: bold;
  transition: 0.1s all ease;

  h1 {
    font-size: 45px;
    ${mediaqueries.md`
      font-size: 90px;
    `}
    line-height: 1.2;
    margin: 0.67em 0;
  }

  span {
    font-size: 14px;
    letter-spacing: 0.15em;
    display: block;
    margin-top: 2rem;
    text-transform: uppercase;
  }
`;

const CenteredMaxWidth = styled.div`
  max-width: ${props => `${props.width || 750}px`};
  margin: 0 auto;
`;

const Subheader = styled.h2`
  font-size: 35px;
  letter-spacing: -0.01em;
  font-weight: bold;
  line-height: 1em;
  color: #525252;
`;

const Hero = styled.div`
  position: relative;
  background-color: hsla(3, 29%, 88%, 1);
  width: 100%;
  min-height: 650px;
  height: 95vh;
`;

const ServiceListContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  ${mediaqueries.md`
    flex-wrap: nowrap;
  `}
`;

const ServiceList = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 40px;

  ${mediaqueries.md`
    flex-basis: 33.33%;
    flex-wrap: nowrap;
    margin-bottom: 0;
  `}
`;

const ListHeader = styled.h3`
  font-size: 24px;
  text-transform: uppercase;
  color: #999;
  font-weight: bold;

  ${mediaqueries.md`
    font-size: 16px;
  `}
`;

const ListItem = styled.div`
  color: #999;
  font-size: 20px;
  padding-bottom: 1em;

  ${mediaqueries.sm`
    font-size: 24px;
  `}

  ${mediaqueries.md`
    font-size: 16px;
  `}
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      quoteImage: file(relativePath: { eq: "quote.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contactImage: file(relativePath: { eq: "contact.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const quoteImageEl = useRef(null);
  const quoteContainer = useRef(null);
  const contactImageEl = useRef(null);
  const contactContainer = useRef(null);
  const screenHeight = typeof window !== 'undefined' ? window.innerHeight : 750;
  const SPEED = 100;

  const {
    containerHeight: quoteHeight,
    percentageScrolled: percentageQuoteScrolled,
  } = usePercentageScrolled({
    containerRef: quoteContainer,
    imageRef: quoteImageEl,
  });
  const {
    containerHeight: contactHeight,
    percentageScrolled: percentageContactScrolled,
  } = usePercentageScrolled({
    containerRef: contactContainer,
    imageRef: contactImageEl,
  });

  return (
    <Layout>
      <SEO title="Pretty Decent Creative" />
      <div>
        <Hero>
          <Overlay>
            <h1>Dedicated to you and your brand. Let’s build together.</h1>
          </Overlay>
        </Hero>
        <div
          ref={quoteContainer}
          style={{
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
            height: quoteHeight,
          }}
        >
          <Image
            ref={quoteImageEl}
            fluid={data.quoteImage.childImageSharp.fluid}
            style={{
              height: screenHeight + SPEED,
              transform: `translateY(-${percentageQuoteScrolled * SPEED}px)`,
            }}
          />
          <Overlay>
            <h1>"Creativity is always a collaboration."</h1>
            <span>Bethany Sarica &nbsp;&nbsp;| &nbsp;&nbsp;Co-founder</span>
          </Overlay>
        </div>
        <CenteredMaxWidth style={{ padding: '0 20px' }}>
          <Spacer size={70} />
          <Spacer size={80} id="about-us" />
          <Subheader>We're pretty decent</Subheader>
          <Spacer size={40} />
          <P style={{marginBottom: '0'}}>
            But like,{' '}
            <span style={{ fontStyle: 'italic' }}>REALLY</span> pretty decent.
          </P>
          <Spacer size={30} />
          <P style={{marginBottom: '0'}}>
            We want to get you looking your best on the web. A thoughtfully
            designed, carefully crafted web presence gets more eyes on your
            business. We want to make the process of reaching brand nirvana as
            easy as sipping an Aperol spritz on a patio in the summer or hitting
            the bottom of a Cheeto's bag during your most recent Netflix binge.
            </P>
          <Spacer size={30} />
          <P style={{marginBottom: '0'}}>
            Life's busy - let us provide you with guidance for your product,
            website and brand so that you can be as hands-off as you would like to be.
            Or maybe hands-off is your nightmare. We consider every project
            a collaboration, and we would be honored to collaborate with you.
          </P>
          <Spacer size={30} />
          <Subheader>Goals</Subheader>
          <Spacer size={40} />
          <P style={{marginBottom: '0'}}>
            Our team has worked with individual clients, events, stores, and
            tech start-ups from coast to coast for over 5 years, and we’d love
            to work with you, too. Well, unless you’re a jerk.
          </P>
          <Spacer size={30} />
          <P style={{marginBottom: '0'}}>
            We pride ourselves on not taking ourselves too seriously. We want you to feel comfortable voicing your creative truth during our process, and that doesn’t come with pretentious attitudes and expensive premiums.
          </P>

          <Spacer size={60} id="services" />
          <Subheader>Services</Subheader>
          <Spacer size={40} />
          <ServiceListContainer>
            <ServiceList>
              <ListHeader>Branding</ListHeader>
              <P style={{ fontWeight: 'bold' }}>_</P>
              <ListItem>Brand Strategy</ListItem>
              <ListItem>Brand Positioning</ListItem>
              <ListItem>Creative Direction</ListItem>
              <ListItem>Brand Voice</ListItem>
              <ListItem>Identity Development</ListItem>
              <ListItem>Brand Guidelines</ListItem>
              <ListItem>Logo &amp; Logotype Design</ListItem>
              <ListItem>Custom Typography</ListItem>
              <ListItem>Color Theory</ListItem>
              <ListItem>Photo Curation</ListItem>
              <ListItem>Packaging</ListItem>
              <ListItem>Print</ListItem>
              <ListItem>Book Design</ListItem>
            </ServiceList>
            <ServiceList>
              <ListHeader>Web Development</ListHeader>
              <P style={{ fontWeight: 'bold' }}>_</P>
              <ListItem>Fresh Websites</ListItem>
              <ListItem>Website Makeovers</ListItem>
              <ListItem>Tech Stack Consultation</ListItem>
              <ListItem>User Experience</ListItem>
              <ListItem>User Flows</ListItem>
              <ListItem>Prototypes</ListItem>
              <ListItem>SEO</ListItem>
              <ListItem>Analytics</ListItem>
              <ListItem>Testing</ListItem>
            </ServiceList>
            <ServiceList>
              <ListHeader>Content Creation</ListHeader>
              <P style={{ fontWeight: 'bold' }}>_</P>
              <ListItem>Curation</ListItem>
              <ListItem>Lifestyle Photography</ListItem>
              <ListItem>Studio Photography</ListItem>
              <ListItem>Copy</ListItem>
              <ListItem>Animation</ListItem>
            </ServiceList>
          </ServiceListContainer>
        </CenteredMaxWidth>
        <Spacer size={100} />

        <div
          ref={contactContainer}
          style={{
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
            height: contactHeight,
          }}
        >
          <Image
            ref={contactImageEl}
            fluid={data.contactImage.childImageSharp.fluid}
            style={{
              height: '100vh',
              transform: `translateY(-${percentageContactScrolled * SPEED}px)`,
            }}
          />
          <Overlay>
            <h1>Ready to Rock?</h1>
            <Link to="/contact">
              <StyledButton>Contact Us</StyledButton>
            </Link>
          </Overlay>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
