import { useState, useLayoutEffect } from 'react';
import useWindowScrollPosition from './useWindowScrollPosition';

/**
 * usePercentageScrolled assists with some parallaxing
 * This should definitely be split out not also to include the container height setting.
 * It's also very specific to GatsbyImage, so that should be abstracted to handle other image types
 * Buuuuuut baby steps
 * @param {Object} params
 * @param {ReactNode} params.containerRef the parent container that will have some height clipped
 * @param {ReactNode} params.imageRef the GatsbyImage ref
 * @param {Number} params.SPEED
 */
export default function usePercentageScrolled({
  containerRef,
  imageRef,
  SPEED = 100,
}) {
  const { y: scrollY } = useWindowScrollPosition();

  const [hasSetContainerHeight, setHasSetContainerHeight] = useState(false);
  const [containerHeight, setContainerHeight] = useState('initial');
  const screenHeight = typeof window !== 'undefined' ? window.innerHeight : 0;

  useLayoutEffect(() => {
    if (!hasSetContainerHeight) {
      const imageHeight =
        imageRef &&
        imageRef.current &&
        imageRef.current.imageRef &&
        imageRef.current.imageRef.current &&
        imageRef.current.imageRef.current.clientHeight;
      setHasSetContainerHeight(!!imageHeight);
      setContainerHeight(`${imageHeight - SPEED}px`);
    }
  }, [SPEED, hasSetContainerHeight, imageRef, scrollY]);

  const { bottom, height } =
    (containerRef.current && containerRef.current.getBoundingClientRect()) ||
    {};
  const isAboveScreen = bottom < 0;
  const isBelowScreen = bottom - height > screenHeight;
  const totalHeight = screenHeight + height;
  let percentageScrolled;
  if (isAboveScreen) {
    percentageScrolled = 1;
  } else if (isBelowScreen) {
    percentageScrolled = 0;
  } else {
    percentageScrolled = 1 - bottom / totalHeight;
  }

  return { containerHeight, percentageScrolled };
}
