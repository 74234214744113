import styled from 'styled-components';

const StyledButton = styled.button`
  display: flex;
  color: white;
  padding: 20px 45px;
  border: 2px solid white;
  border-radius: 5px;
  font-weight: 700;
  letter-spacing: 2.8px;
  font-size: 12px;
  text-transform: uppercase;
  background-color: transparent;
  margin: ${props => props.margin || '0 auto'};
  cursor: pointer;
  transition: 0.2s all linear;
  &:hover {
    background-color: white;
    color: black;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: 0.2s all ease;
  }
`;

export default StyledButton;
