import { useState, useEffect } from 'react';

/**
 * useWindowScrollPosition grabs the x and y scroll coordinates of the screen
 */

let supportsPassive = false;
try {
  const opts = Object.defineProperty({}, 'passive', {
    // eslint-disable-next-line
    get() {
      supportsPassive = true;
    },
  });
  window.addEventListener('testPassive', null, opts);
  window.removeEventListener('testPassive', null, opts);
} catch (e) {
  console.log('error', e);
}

const getPosition = () => {
  if (typeof window === `undefined`) {
    return { x: 0, y: 0 };
  }
  return {
    x: window.pageXOffset,
    y: window.pageYOffset,
  };
};

export default function useWindowScrollPosition() {
  const [position, setPosition] = useState(getPosition());

  useEffect(() => {
    if (typeof window === `undefined`) {
      return () => {};
    }

    const handleScroll = () => {
      setPosition(getPosition());
    };

    window.addEventListener(
      'scroll',
      handleScroll,
      supportsPassive ? { passive: true } : false
    );

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return position;
}
